import { mapGetters } from "vuex";
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import MasterService from "@/core/services/master.service";
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: "FormCreateUpdateTH",
  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear",
     
    ]),
    getLogo(){
      return process.env.BASE_URL+'media/logos/logo.jpg';
    },
    routeName(){
      return this.$route.name;
    },

  },
  components: {
    // Dropdown1
    VueHtml2pdf
  },

  data() {
    return {
      // :core
      dataUser: {},
      searchMain: {},
      role_kode : null,
      user_id : null,
      user_id_induk :null,
      // routeName: null,
      // :end core

      // :form
      form: {},
      formDefault: {
        Jamaah_id: undefined,
        suppbranch_id: undefined,
      },
      formValid: false,
      // :end form
      
      // :contents 
      tContents: [
        // { product_name: '1TEST', prediction_price: '110000', jumlah: '11', bin_number: '11', volume: '11', weight: '11', expired_date: '29/10/29'}
      ],
      tContents2 : [],
      tContents3 : [],
      tContentsTotal: 1,
      tContentsHeader: [
        { text: 'Jumlah', align: 'start', value: 'qty', sortable: false},
        { text: 'Length', align: 'start', value: 'length', sortable: false},
        { text: 'Width', align: 'start', value: 'width', sortable: false},
        { text: 'Height', align: 'start', value: 'height', sortable: false},
        { text: 'Volume / Matrix', align: 'start', value: 'volume_matrix', sortable: false},
        { text: 'Actual Weight', align: 'start', value: 'actual_weight', sortable: false},
        { text: 'Chargeable weight', align: 'start', value: 'chargeable_weight', sortable: false},
        { text: 'Price', align: 'start', value: 'price', sortable: false},
        { text: 'Actions', align: 'end', value: 'actions', sortable: false},
      ],
      
      tContentsOptions: {},
      tContentsLoading: false,
      tContentsDisabled: false,
      tContentsPaginations: [5,10,15,25,50,100],
      tContentsSearch: {},
      // :end contents
      
      // :details
      tDetails: {},
      tDetailsLoading: {},
      // :end details
      
      // :additional

      selectForm: null,
      dp1: false,
      dp2: false,
      dp3: false,
      param_id: null,

      // #add product
      modalAddProduct: false,
      modalAddBehalf: false,
      formAddValid: false,
      formAddValid2: false,
      formAdd: {
        chargeable_weight : 0,
        actual_weight : 0,
        volume_matrix : 0,
      },
      formAdd2: {},
      formAddTitle: null,
      formAddTitle2: null,
      // #end add product

      // #add Master Product
      modalAddMasterProduct: false,
      formAddMasterValid: false,
      formAddMaster: {},
      // #end add Master Product
      dataDN :[],
      loadingDN: false,
      dataBranch: [],
      dataBranchReceived : [],
      loadingBranch: false,
      loadingBranchReceived : false,
      dataJenisPengiriman: [],
      loadingJenisPengiriman: false,
      dataJenisPengirimanDetail: [],
      loadingJenisPengirimanDetail: false,
      dataSatuan: [],
      loadingSatuan: false,
      loadingCustomer: false,
      dataCustomer: [],
      loadingShipper: false,
      dataShipper: [],
      loadingStatusTracking: false,
      dataStatusTracking: [],
      loadingProvince: false,
      dataProvince: [],
      dataProvinceDes: [],
      dataRegency : [],
      dataRegencyDes : [],
      dataTipePembayaran : [],
      loadingOngkosKirim : false,

      // :validation
      commonValidation: [v => !!v || 'This field is required'],
      // :end validation
    };
  },

  created(){
    this.form = {...this.formDefault};
  },

  mounted() {
    this.param_id = this.$route.params.id;

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);
    this.searchMain.role_kode = Number(this.currentUser.role_kode);
    this.role_kode = this.currentUser.role_kode;
    this.user_id = Number(this.currentUser.id);

    this.loadBranch();
    this.loadBranchReceived();
    this.loadJenisPengiriman();
    this.loadCustomer();
    this.loadShipper();
    this.loadProvince();
    this.loadProvinceDes();
    this.loadTipePembayaran();
    if(this.param_id){
      this.loadDetailTransaksi(this.param_id);
    }
    

    // alert(this.routeName);
  },

  watch: {
    selectForm(val){
      if(val){
        this.form = {};
        this.formAdd = {};
        this.tContents = [];
      }
    },
    
  },

  methods: {
    async generateReport() {
      this.loadingGenerate = true;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf()
      }, 50);
      setTimeout(() => {
        this.loadingGenerate = false;
      }, 2000);
    },
   async  submitTransaction(){
      if(!this.$refs.vForm.validate()) return;

      this.$swal({
        title: 'Periksa kembali data anda!',
        html: "Pastikan data yang akan anda jual sudah benar.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit",
      }).then((result) => {
        if(result.isConfirmed){

          let content = {...this.form};
          content.detail      = this.tContents;

         
          
          content.isAction    = this.routeName == 'transactions-update-surat_jalan' ? "update" : "create";
          
          let query = {
            main: this.searchMain,
            data : content
          }

          // ApiService.setHeader();
          this.tContentsLoading = true;
          ApiService.post("surat_jalan/create", query, 1).then(({ data }) => {
            this.tContentsLoading = false;
            this.$swal("Berhasil", data.message, "success");
            this.$router.push({ path: "/ekspedisi/transactions/surat_jalan-list" });
          }).catch(({ response }) => {
            this.tContentsLoading = false;
            return this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })
        }
      })
    },
    async loadTipePembayaran(){
      this.loadingBranch = true;
      MasterService.loadMaster("dropdown", "paymentTerm").then(({ data }) => {
        this.loadingBranch = false;
        this.dataTipePembayaran = data.data;
      }).catch((response)=>{
        this.loadingBranch = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    async loadDN(val){
      var query = {
        code_customer: val,
        tanggal : this.form.tanggal
      }
      this.loadingDN = true;
      ApiService.post("/dropdown/masterDNFilter", query, 1).then(({data}) => {
        this.loadingDN = false;
        this.dataDN = data.data;
      }).catch((response)=>{
        this.loadingDN = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
   async loadBranch(){
      this.loadingBranch = true;
      MasterService.loadMaster("dropdown", "Branch").then(({ data }) => {
        this.loadingBranch = false;
        this.dataBranch = data.data;
      }).catch((response)=>{
        this.loadingBranch = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
   async  loadJenisPengiriman(){
      this.loadingJenisPengiriman = true;
      MasterService.loadMaster("dropdown", "jenis_pengiriman").then(({ data }) => {
        this.loadingJenisPengiriman = false;
        this.dataJenisPengiriman = data.data;
      }).catch((response)=>{
        this.loadingJenisPengiriman = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    async loadBranch(){
      this.loadingBranch = true;
      MasterService.loadMaster("dropdown", "branch").then(({ data }) => {
        this.loadingBranch = false;
        this.dataBranch = data.data;
      }).catch((response)=>{
        this.loadingBranch = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    async loadBranchReceived(){
      this.loadingBranchReceived = true;
      MasterService.loadMaster("dropdown", "branch").then(({ data }) => {
        this.loadingBranchReceived = false;
        this.dataBranchReceived = data.data;
      }).catch((response)=>{
        this.loadingBranchReceived = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
   async  loadShipper(){
      this.loadingShipper = true;
      MasterService.loadMaster("dropdown", "customer").then(({ data })=>{
        this.loadingShipper = false;
        this.dataShipper = data.data;
      }).catch((response)=>{
        this.loadingShipper = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    async changeActualWieght(){
      this.formAdd = {...this.formAdd};
      this.formAdd.chargeable_weight =this.formAdd.actual_weight;
    },
    async changeLength(){
      this.formAdd = {...this.formAdd};
      this.formAdd.volume_matrix = parseInt((parseInt(this.formAdd.length ?? 0 ) * parseInt( this.formAdd.width ?? 0) * parseInt(this.formAdd.height ?? 0)) / 6000);
      if(parseInt(this.formAdd.volume_matrix) < parseInt(this.formAdd.actual_weight)){
        this.formAdd.chargeable_weight = parseInt(this.formAdd.actual_weight) ?? 0;
      }else{
        this.formAdd.chargeable_weight = parseInt(this.formAdd.volume_matrix) ?? 0;
      }
    },
    async changeWidth(){
      this.formAdd = {...this.formAdd};
      this.formAdd.volume_matrix = parseInt((parseInt(this.formAdd.length ?? 0 ) * parseInt( this.formAdd.width ?? 0) * parseInt(this.formAdd.height ?? 0)) / 6000);
      if(parseInt(this.formAdd.volume_matrix) < parseInt(this.formAdd.actual_weight)){
        this.formAdd.chargeable_weight = parseInt(this.formAdd.actual_weight) ?? 0;
      }else{
        this.formAdd.chargeable_weight = parseInt(this.formAdd.volume_matrix) ?? 0;
      }
    },
    async changeHeight(){
      this.formAdd = {...this.formAdd};
      this.formAdd.volume_matrix = parseInt((parseInt(this.formAdd.length ?? 0 ) * parseInt( this.formAdd.width ?? 0) * parseInt(this.formAdd.height ?? 0)) / 6000);
      if(parseInt(this.formAdd.volume_matrix) < parseInt(this.formAdd.actual_weight)){
        this.formAdd.chargeable_weight = parseInt(this.formAdd.actual_weight) ?? 0;
      }else{
        this.formAdd.chargeable_weight = parseInt(this.formAdd.volume_matrix) ?? 0;
      }
    },
    async getOngkosKirim(){ 
      if(typeof this.form.jenis_pengiriman_id === 'undefined'){
        this.$swal("Failed","Pilih jenis pengiriman dulu", "error");
        this.form.bill_to = "";
        return false;
      }

      if(typeof this.form.destination_id === 'undefined'){
        this.$swal("Failed","Pilih Penerima terlebih dulu", "error");
        this.form.bill_to = "";
        return false;
      }

      if(typeof this.form.origin_id === 'undefined'){
        this.$swal("Failed","Pilih Pengirim terlebih dulu", "error");
        this.form.bill_to = "";
        return false;
      }

      if(typeof this.form.satuan_id === 'undefined'){
        this.$swal("Failed","Pilih satuan terlebih dulu", "error");
        this.form.bill_to = "";
        return false;
      }

      if(typeof this.form.jenis_pengiriman_detail_id === 'undefined'){
        this.$swal("Failed","Pilih jenis pengiriman detail dulu", "error");
        this.form.bill_to = "";
        return false;
      }

      var query = { 
        main: this.searchMain,
        data: {
          jenis_pengiriman_id: this.form.jenis_pengiriman_id,
          destination_id: this.form.destination_id,
          bill_to: this.form.bill_to,
          satuan_id: this.form.satuan_id,
          origin_id: this.form.origin_id,
          jenis_pengiriman_detail_id: this.form.jenis_pengiriman_detail_id,
        }
      }

      if(this.form.bill_to != null){
        this.loadingOngkosKirim = true;
        ApiService.post("/ongkos_kirim/get_ongkos_kirim", query, 1).then(({data}) => {
          this.loadingOngkosKirim = false;
          this.formAdd = {...this.formAdd};
          this.form.price = data.data;
        }).catch((response)=>{
          this.loadingOngkosKirim = false;
          this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
        })
      }
    
      
    },
    async changeShipper(val){
      var query = {
        id: val
      }
      
      if(val != null){
        this.loadingShipper = true;
        ApiService.post("/getCustomerId", query, 1).then(({data}) => {
          this.loadingShipper = false;
          console.log(data.data.regency_id);
          this.form.shipper_address = data.data.address;
          this.form.shipper_telp = data.data.telp;
          this.form.province_id = parseInt(data.data.province_id);
          this.loadRegency(this.form.province_id);
          this.form.origin_id = parseInt(data.data.regency_id);
        }).catch((response)=>{
          this.loadingShipper = false;
          this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
        })
      }else{
          this.form.shipper_address = "";
          this.form.shipper_telp ="";
          this.form.province_id = "";
          this.form.origin_id = "";
      }
    },
    async getCustomerDN(items){
      console.log(items);
      console.log(this.dataDN);
            // Filter data_arr berdasarkan items
      const filteredData = this.dataDN.filter(item => items.includes(item.value));
      const totalColy = filteredData.reduce((total, item) => total + parseInt(item.colly), 0);
      const uniqueSquanNumbers = [...new Set(filteredData.map(item => item.squan_number))];
      const squanNumber =  uniqueSquanNumbers.join(",");
      const uniqueNoMobile = [...new Set(filteredData.map(item => item.no_mobile))];
      const noMobile =  uniqueNoMobile.join(",");
      this.form.total_coly = totalColy;
      this.form.sequend_number = squanNumber;
      this.form.no_mobile = noMobile;

    //   let index = this.dataDN.findIndex(res => res.value === items);
    //   this.form.sequend_number = this.dataDN[index].squan_number;
    //  ;
    //   this.form.no_mobile = this.dataDN[index].no_mobile;
      // this.changeCustomer(this.dataDN[index].code_customer);
    },
   async  changeCustomer(val){
    
      var query = {
        id: val
      }
      
      if(val != null){
        this.loadingCustomer = true;
        ApiService.post("/getCustomerId", query, 1).then(({data}) => {
          this.loadingCustomer = false;
          console.log(data.data);
          this.form.customer_id = parseInt(data.data.id);
          this.form.customer_address = data.data.address;
          this.form.customer_telp = data.data.telp;
          this.form.province_destination_id = parseInt(data.data.province_id);
          this.loadRegencyDes(this.form.province_destination_id);
          this.form.destination_id = parseInt(data.data.regency_id);
          this.loadDN(data.data.code);
        }).catch((response)=>{
          this.loadingCustomer = false;
          this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
        })
      }else{
          this.form.customer_address = "";
          this.form.customer_telp ="";
          this.form.province_destination_id = "";
          this.form.destination_id = "";
      }
    },
    loadCustomer(){
      this.loadingCustomer = true;
      MasterService.loadMaster("dropdown", "customer").then(({ data })=>{
        this.loadingCustomer = false;
        this.dataCustomer = data.data;
      }).catch((response)=>{
        this.loadingCustomer = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    loadProvince(){
      this.loadingProvince = true;
      MasterService.loadMaster("dropdown", "province").then(({ data })=>{
        this.loadingProvince = false;
        this.dataProvince = data.data;
      }).catch((response)=>{
        this.loadingProvince = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    loadProvinceDes(){
      this.loadingProvince = true;
      MasterService.loadMaster("dropdown", "province").then(({ data })=>{
        this.loadingProvince = false;
        this.dataProvinceDes = data.data;
      }).catch((response)=>{
        this.loadingProvince = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    loadJenisPengirimanDetail(val){
      var query = {
        jenis_pengiriman_id: val
      }

      this.loadingJenisPengirimanDetail = true;
      ApiService.post("dropdown/jenis_pengiriman_detail", query, 1).then(({data}) => {
        this.loadingJenisPengirimanDetail = false;
        this.dataJenisPengirimanDetail = data.data;
      }).catch((response)=>{
        this.loadingJenisPengirimanDetail = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })

      var query = {
        jenis_pengiriman_id: val
      }

      this.loadingSatuan = true;
      ApiService.post("dropdown/satuan", query, 1).then(({data}) => {
        this.loadingSatuan = false;
        this.dataSatuan = data.data;
      }).catch((response)=>{
        this.loadingSatuan = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })

      var query = {
        jenis_pengiriman_id: val
      }

      this.loadingStatusTracking = true;
      ApiService.post("dropdown/status_tracking", query, 1).then(({data}) => {
        this.loadingStatusTracking = false;
        this.dataStatusTracking = data.data;
      }).catch((response)=>{
        this.loadingStatusTracking = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    loadRegency(val){
      var query = {
        id: val
      }

      this.loadingRegency = true;
      ApiService.post("dropdown/regency", query, 1).then(({data}) => {
        this.loadingRegency = false;
        this.dataRegency = data.data;
        console.log(this.dataRegency);
      }).catch((response)=>{
        this.loadingRegency = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    loadRegencyDes(val){
      var query = {
        id: val
      }

      this.loadingRegency = true;
      ApiService.post("dropdown/regency", query, 1).then(({data}) => {
        this.loadingRegency = false;
        this.dataRegencyDes = data.data;
        console.log(this.dataRegency);
      }).catch((response)=>{
        this.loadingRegency = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    async showModalAddProduct(content = null){
      if( typeof this.form.price === "undefined"){
        this.$swal("Failed","Ongkos kirim masih kosong", "error");
        return false;
      }else{
        if(this.$refs.vFormAdd) this.$refs.vFormAdd.reset();

        this.formAdd = {};
          this.formAddTitle = "Add";
          if(content){
            setTimeout(() => {
              console.log(content);

              this.formAddTitle = "UPDATE";
              this.formAdd = {...content};
            }, 50);
          }else{
            this.tContents2 = [];
          }
    
          this.modalAddProduct = true;
      }
      
    },
    async submitAddContent(){
      if(!this.$refs.vFormAdd.validate()) return;

      let content = {...this.formAdd};
  
      if(content.id){
        let index = this.tContents.findIndex(res => res.id === content.id);
        this.tContents[index].qty   = content.qty;
        this.tContents[index].length     = content.length;
        this.tContents[index].width          = content.width;
        this.tContents[index].height       = content.height;
        this.tContents[index].actual_weight         = content.actual_weight;
        this.tContents[index].volume_matrixe         = content.volume_matrixe;
        this.tContents[index].chargeable_weight            = content.chargeable_weight;
        this.tContents[index].price   = parseInt(content.chargeable_weight) * this.form.price;
        

        let keepContent = [...this.tContents];
        this.tContents = [];
        this.tContents = keepContent;
       
        
      }else{
        content.id = Math.random().toString(36).substring(7);
        content.price = parseInt(content.chargeable_weight) * this.form.price;
        this.tContents.push(content);
      }

      this.formAdd = {};
      this.modalAddProduct = false;
      this.$refs.vFormAdd.reset();
      
    },
    deleteTransaction(val){
      let index = this.tContents.findIndex(res => res.id === val);
      if (index >= 0) {
        this.tContents.splice(index, 1);
      }
    },
   
    async loadDetailTransaksi(param_id){
      if(!this.param_id) return;
      
      this.tDetailsLoading = true;

      var query = { 
        main: this.searchMain,
        data: { id : param_id }
      }

      ApiService.setHeader();
      ApiService.post("surat_jalan/detail", query, 1).then(({ data }) => {
        this.tDetailsLoading = false;
        this.tDetails = data.detail;
        this.setForm(data.detail);
      }).catch(({ response }) => {
        this.tDetailsLoading = false;
        this.$router.push({ path: "/ekspedisi/transactions/surat_jalan-list" });
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    async setForm(content){
      this.form = {...this.vForm};
      this.form.id = content.id;
      this.form.tanggal = content.date;
      this.form.no_surat_jalam = content.no_surat_jalam;
      this.form.branch_id = parseInt(content.branch_id);
      this.form.branch_received = parseInt(content.branch_received);
      this.form.jenis_pengiriman_id = parseInt(content.jenis_pengiriman_id);
      this.loadJenisPengirimanDetail(this.form.jenis_pengiriman_id);
      this.form.jenis_pengiriman_detail_id = parseInt(content.jenis_pengiriman_detail_id);
      this.form.satuan_id = parseInt(content.satuan_id);
      this.form.estimasi_date = content.estimasi_date;
      this.form.nama_vendor = content.nama_vendor;
      this.form.harga_vendor = content.harga_vendor;
      this.form.total_coly = content.total_coly;
      console.log(content.items);
      const validIds = ['537', '604'];
      if (validIds.includes(content.shipper_id) || validIds.includes(content.customer_id)) {
          this.form.items = content.items.split(",");
      } else {
          this.form.items = content.items;
      }
      this.changeCustomer(content.customer_id);
      this.form.shipper_id = parseInt(content.shipper_id);
      this.form.customer_id = parseInt(content.customer_id);
      this.changeShipper(content.shipper_id);
      this.form.status = parseInt(content.status);
      this.form.bill_to = parseInt(content.bill_to);
      this.form.jenis_barang = content.jenis_barang;
      this.form.sequend_number = content.sequend_number;
      this.form.payment_term_id = parseInt(content.payment_term_id);
      this.form.price = content.price;
      this.form.no_mobile = content.no_mobile;
      this.form.biaya_lainnya = content.biaya_lainnya;
      content.transaction_details.forEach(element => {
        let dataStock = [];
        let row = {
          id: element.id,
          qty: element.qty,
          length: element.length,
          width: element.width,
          height: element.height,
          actual_weight : element.actual_weight,
          volume_matrix :element.volume_matrix,
          chargeable_weight: element.chargeable_weight,
          price : element.price
        }
        dataStock.push(row);

        this.tContents = dataStock;
      });
      
    },
    // ::end loader
  },
  
};